import React,{createContext,useContext,useReducer} from 'react';

const UserContent = React.createContext();


export const UserProvider = ({intialstate,reducer,children}) =>{
    return (
        <UserContent.Provider value={useReducer(reducer,intialstate)}>
        {children}
        </UserContent.Provider>
        )

};


export const UserValue = () => useContext(UserContent);