import React from 'react'

function openWhatsapp(num) {
    window.open("https://wa.me/"+num+"?text=Hello%20I%20want%20to%20make%20book%20appoinment", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=60,left=500,bottom=300,width=1000,height=900");
}


export default function ContactUs(){
    return(

        <div className="contact-sect" style ={{color:'#000',padding: '0',
        width: '100%',fontSize: '15px', lineHeight: '28px', textAlign: 'left' }}>
            <div>
            <img src ={require("../img/img/phone-call.png")} width="19px" height="19px" style ={{margin:'0 8px'}}/>
            <a href="tel:+918042489999" style ={{color:'#000'}}><b>Phone:</b> +91 - 8042489999</a>
            </div>
            <div>
            <img src ={require("../img/img/email.png")} width="20px" height="23px" style ={{margin:'0 8px'}} />
            <a href = "mailto: care@nuhospitals.com"  style ={{color:'#000', textTransform: 'none'}}><b>Email:</b> care@nuhospitals.com</a>
            </div>
            <div>
            <img src ={require("../img/img/whatsapp.png")} width="20px" height="20px" style ={{margin:'0 8px'}} /><b>Whatsapp:</b>
            <button className="btn-cnt" onClick={()=>openWhatsapp("+91-8951889724")} target="_blank" style ={{color:'#000'}}>  +91-8951889724</button>
            {/* <button className="btn-cnt" onClick={()=>openWhatsapp("+91-8951889724")} target="_blank" style ={{color:'#000'}}><b>Rajajinagar : </b> +91-8951889724</button> */}
            {/* <button className="btn-cnt" onClick={()=>openWhatsapp("+91 8951889724")} target="_blank" style ={{color:'#000'}}><b>Padmanabha Nagar : </b> +91 8951889724</button> */}
            </div>
        </div>
    )
}