//export  const BASE_URL = "http://dev01-api.wegren.com/api/";
//export const IMAGE_URL = "http://dev01-api.wegren.com/";


// export  const BASE_URL = "https://endpoint.in.ext.con.nufertility.com/api/";
// export const IMAGE_URL = "https://endpoint.in.ext.con.nufertility.com/";


 export  const BASE_URL = "https://endpoint.in.ext.con.nuhospitals.com/api/";
 export const IMAGE_URL = "https://endpoint.in.ext.con.nuhospitals.com/";
 export const BASE_URL_Websocket = "ws://endpoint.in.ext.con.nuhospitals.com/ws/";

//  export  const BASE_URL = "http://localhost:8000/api/";
//  export  const IMAGE_URL = "http://localhost:8000/";
// export const BASE_URL_Websocket = "ws://localhost:8000/ws/";

//  export const IMAGE_URL = "https://endpoint.in.ext.con.nuhospitals.com/";